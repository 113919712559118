import { useCallback, useMemo } from "react";
import Link from "next/link";
import { useEvent } from "lib/contexts/eventContext";
import { UIEvent } from "lib/enums";

interface PromotionBannerProps {
  banner: ResPromotionBanner;
  pagination?: string;
  order?: number;
  className?: string;
}

export const PromotionBanner: React.FC<PromotionBannerProps> = ({
  banner,
  pagination,
  order,
  className,
}) => {
  const { track } = useEvent();
  const { title, text1, text2, image, theme, link } = banner;
  const [color, bgColor] = theme.split("/");
  const href = useMemo(() => {
    if (link.type === "promotionPage") {
      return `/promotions/${link.slug}`;
    }
    if (link.type === "immigration101") {
      return `/resources/${link.slug}`;
    }
    return link.url || "";
  }, [link]);

  const handleOnClick = useCallback(() => {
    track(UIEvent.HubPromotionBannerClicked, {
      title,
      order,
    });
  }, [order, title, track]);

  return (
    <Link href={href} passHref>
      <a
        css={`
          display: block;
          box-sizing: border-box;
          width: 100%;
          height: 162px;
          color: ${color};
          :hover {
            color: ${color};
          }
        `}
        onClick={handleOnClick}
        className={className}
      >
        <div
          css={`
            position: relative;
            overflow: hidden;
            border-radius: 20px;
            padding: 20px;
            height: 100%;
            background-color: ${bgColor};
          `}
        >
          <div
            css={`
              position: absolute;
              bottom: 0;
              right: 0;
              width: 130px;
              height: 80px;
            `}
          >
            <img
              css={`
                width: 100%;
                height: auto;
              `}
              src={image}
              alt=""
            />
          </div>
          <div
            css={`
              position: relative;
              padding: 10px 10px 0;
              line-height: 26px;
              font-size: 18px;
              font-weight: 700;
            `}
          >
            <div>{text1}</div>
            <div>{text2}</div>
          </div>
          {!!pagination && (
            <div
              css={`
                position: absolute;
                bottom: 30px;
                left: 30px;
                border-radius: 10px;
                padding: 1px 5px;
                line-height: 12px;
                font-size: 10px;
                background-color: rgba(0, 0, 0, 0.6);
                color: white;
              `}
            >
              {pagination}
            </div>
          )}
        </div>
      </a>
    </Link>
  );
};
