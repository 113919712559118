import { useCallback } from "react";
import Link from "next/link";
import IconChevron from "lib/assets/chevron-right-2.svg";
import { UIEvent } from "lib/enums";
import { useEvent } from "lib/contexts/eventContext";

interface ResourceLinkProps {
  title: string;
  href: string;
  event?: UIEvent;
}

const ResourceLink: React.FC<ResourceLinkProps> = ({ title, href, event }) => {
  const { track } = useEvent();

  const handleOnClick = useCallback(() => {
    if (event) {
      track(event);
    }
  }, [event, track]);

  return (
    <Link href={href} passHref>
      <a
        css={`
          position: relative;
          width: 100%;
        `}
        onClick={handleOnClick}
      >
        <div
          css={`
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            padding: 22px 30px;
            line-height: 26px;
            font-size: 18px;
            color: black;
            background-color: #f9f9fb;
            transition: all 0.15s ease-in-out;
            a > &:hover {
              color: black;
              transform: translateY(-5px);
              box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
            }
          `}
        >
          <div
            css={`
              flex: 1;
              margin-right: 20px;
            `}
          >
            {title}
          </div>
          <IconChevron />
        </div>
      </a>
    </Link>
  );
};

export default ResourceLink;
