import React, { useCallback } from "react";
import styled, { useTheme } from "styled-components";
import MuiBox from "@material-ui/core/Box";
import MuiTypography from "@material-ui/core/Typography";
import IconStar from "lib/assets/rating-star-icon.svg";

interface Props {
  /**
   * Rating.
   */
  rating: number;
  /**
   * Size of star icon (px).
   */
  size?: number;
  /**
   * Gutter between star icons (px).
   */
  gutter?: number;
  /**
   * Should rating text be hidden?
   */
  hideText?: boolean;
  /**
   * ARIA label.
   */
  "aria-label"?: string;
  /**
   * Make rating editable
   */
  onChange?: (rating: number) => any;
}

/**
 * Full rating..
 */
const FULL_RATING = 5;
/**
 * An array to render the stars.
 */
const FIVE_ITEMS = [...Array(FULL_RATING)];

/**
 * Displays star rating.
 */
const Rating: React.FC<Props> = ({
  rating,
  size = 19,
  gutter = 0,
  hideText = false,
  onChange,
}) => {
  if (process.env.NODE_ENV !== "production") {
    if (rating < 0 || rating > FULL_RATING) {
      throw new Error(
        `Rating must be number between 0 and ${FULL_RATING}. received ${rating}.`,
      );
    }
  }

  const makeHandleOnStarPressed = useCallback(
    (rating: number) => {
      return () => onChange?.(rating);
    },
    [onChange],
  );

  return (
    <MuiBox
      display="inline-block"
      aria-label={`${rating.toFixed(1)} out of ${FULL_RATING}`}
    >
      <MuiBox display="flex" alignItems="center" justifyContent="flex-start">
        {/* Stars */}
        {FIVE_ITEMS.map((_, i) => (
          <MuiBox
            key={i}
            ml={i === 0 ? 0 : `${gutter}px`}
            width={size}
            height={size}
            display="flex"
            alignItems="center"
            justifyContent="center"
            aria-hidden={true}
          >
            <RatingStar
              percent={computeStarPercent(rating, i)}
              size={size}
              onClick={onChange ? makeHandleOnStarPressed(i + 1) : undefined}
            />
          </MuiBox>
        ))}

        {/* Text */}
        {!hideText && (
          <MuiBox pl={1.5} aria-hidden={true}>
            <MuiTypography>{rating}</MuiTypography>
          </MuiBox>
        )}
      </MuiBox>
    </MuiBox>
  );
};

interface RatingStar {
  /**
   * The rate at which an empty star should be filled
   */
  percent: number;
  /**
   * The size of star icon.
   */
  size: number;
  onClick?: () => any;
}

/**
 * Displays a star icon.
 */
const RatingStar: React.FC<RatingStar> = ({ percent, size, onClick }) => {
  return (
    <MuiBox width={size} height={size} position="relative" onClick={onClick}>
      <MuiBox
        position="absolute"
        width={size}
        height={size}
        top={0}
        left={0}
        display="flex"
        alignItems="center"
      >
        <StarEmpty size={size} />
      </MuiBox>
      <MuiBox
        position="absolute"
        top={0}
        left={0}
        width={(size * percent) / 100}
        height={size}
        overflow="hidden"
        display="flex"
      >
        <MuiBox display="flex">
          <StarFull size={size} />
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

/**
 * Displays an empty star.
 */
const StarEmpty = ({
  size = 24,
  onClick,
}: {
  size?: number;
  onClick?: () => any;
}) => {
  const theme = useTheme();

  return (
    <StarIcon size={size} fill={theme.palette.grey[200]} onClick={onClick} />
  );
};

/**
 * Displays a full star.
 */
const StarFull = ({
  size = 24,
  onClick,
}: {
  size?: number;
  onClick?: () => any;
}) => {
  return <StarIcon size={size} onClick={onClick} />;
};

/**
 * Displays a star icon.
 */
const StarIcon = ({
  size = 24,
  fill,
  onClick,
}: {
  size?: number;
  fill?: string;
  onClick?: () => any;
}) => {
  const theme = useTheme();

  return (
    <StyledIcon
      size={size}
      width={size}
      height={size}
      fill={fill ?? theme.palette.primary.main}
      onClick={onClick}
    />
  );
};

/**
 * NOTE: A trick to set viewbox value of Icon component differently from width and height.
 */
const StyledIcon = styled(IconStar)`
  width: ${(props: { size: number }) => `${props.size}px`};
  height: ${(props: { size: number }) => `${props.size}px`};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;

/**
 * Given a rating, computes the percent of idx-th star.
 *
 * @param {number} rating rating
 * @param {number} idx star's position
 * @returns {number} percentage
 */
const computeStarPercent = (rating: number, idx: number): number =>
  Math.round(Math.min(Math.max(rating - idx, 0), 1) * 100);

export default Rating;
